<template>
  <svg :width="width" :height="height" viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-330.000000, -300.000000)">
            <g transform="translate(30.000000, 149.000000)">
                <g transform="translate(300.000000, 151.000000)">
                    <circle id="椭圆形备份" :stroke="colors[0]" stroke-width="4" fill-opacity="0.2" :fill="colors[1]" cx="40" cy="40" r="38"></circle>
                    <path d="M48.4477369,28.8611527 L61.4540703,50.9719195 C62.5741482,52.876052 61.9385464,55.3276589 60.0344139,56.4477369 C59.4197074,56.809329 58.7195045,57 58.0063334,57 L31.9936666,57 C29.7845276,57 27.9936666,55.209139 27.9936666,53 C27.9936666,52.2868289 28.1843376,51.5866261 28.5459297,50.9719195 L41.5522631,28.8611527 C42.6723411,26.9570202 45.123948,26.3214184 47.0280805,27.4414963 C47.6142952,27.7863285 48.1029047,28.274938 48.4477369,28.8611527 Z" id="三角形" fill="#FFFFFF" transform="translate(45.000000, 40.000000) rotate(90.000000) translate(-45.000000, -40.000000) "></path>
                </g>
            </g>
        </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'YbSvgPlay',
  props: {
    colors: Object,
    width: {
      type: String,
      default: '80'
    },
    height: {
      type: String,
      default: '80'
    }
  }
}
</script>
