
import { OpenAppEnum } from './types'
import { PropType } from 'vue'

interface IHeaderState {
  handleDownload: () => void;
}

export default {
  props: {
    background: {
      type: String as PropType<string>
    },
    headerLogo: {
      type: String as PropType<string>,
      default: 'logo.png'
    },
    headerNameLogo: {
      type: String as PropType<string>,
      default: 'name.png'
    },
    buttonText: {
      type: String as PropType<string>,
      default: '下载App'
    },
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: true
    }
  },
  setup (props: Readonly<{
    handleOpenApp: (position: OpenAppEnum) => void;
  }>): IHeaderState {
    const handleDownload = () => {
      props.handleOpenApp(OpenAppEnum.DOWNLOAD)
    }
    return {
      handleDownload
    }
  }
}
