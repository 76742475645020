/**
 * 接口axios实例
 * Author: Huangzhh
 */

import Axios, { AxiosInstance } from 'axios'
import { isAxiosSuccess } from '../api/interface'
import networdError from '../api/networdError'
import { Toast } from 'vant'

/**
  *
  */
const server: AxiosInstance = Axios.create({
  timeout: 20000
})

// 游戏服务端 请求拦截
server.interceptors.request.use(
  (config) => {
    return config
  },
  async (error: Error) => {
    return Promise.reject(error)
  }
)

// 游戏服务端 响应拦截
server.interceptors.response.use((response) => {
  const res = response.data
  if (res.status !== 1) {
    // 主动上报失败接口
    // const sentryMsg = {
    //   request: response.config,
    //   response: response.data
    // };
    // sentryManager.captureMessage(JSON.stringify(sentryMsg), Severity.Error);

    return networdError(res)
  }
  if (isAxiosSuccess(res)) {
    return response.data
  } else {
    return Promise.reject(res)
  }
}, async err => {
  if (err.message === 'Network Error') {
    Toast.fail('网络无法连接，请检查网络后重试')
  }
  return Promise.reject(err)
})

export default server
