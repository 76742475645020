<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>ic_tap_icon_2</title>
        <g id="落地页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g
                transform="translate(-200.000000, -798.000000)"
                fill="#FFA857"
                fill-rule="nonzero"
                id="编组-5"
            >
                <g transform="translate(0.000000, 790.000000)">
                    <g id="编组-4备份" transform="translate(189.000000, 0.000000)">
                        <g
                            id="ic_tap_icon_2"
                            transform="translate(23.000000, 20.000000) scale(-1, 1) translate(-23.000000, -20.000000) translate(11.000000, 8.000000)"
                        >
                            <path
                                d="M12,1 C5.92526222,1 1,5.92523778 1,12 C1,18.0754467 5.92523778,23 12,23 C18.0747622,23 23,18.0754467 23,12 C23,5.92526222 18.0747622,1 12,1 Z M18,14.4252332 L12.6666403,14.4252332 L12.6666403,18 L11.3333123,18 L11.3333123,14.4252332 L6,14.4252332 L6,12.9953265 L11.3333123,12.9953265 L11.3333123,10.8504665 L6.66666403,10.8504665 L6.66666403,9.42055976 L10.0566595,9.42055976 L6.87866912,6.01166217 L7.82200169,5 L11.7886586,9.25397881 L11.6333289,9.42055976 L12.3673348,9.42055976 L12.2126688,9.25469058 L16.1793257,5.00071178 L17.1226583,6.01237395 L13.9433405,9.42055976 L17.333336,9.42055976 L17.333336,10.8504665 L12.6666877,10.8504665 L12.6666877,12.9953265 L18,12.9953265 L18,14.4252332 L18,14.4252332 Z"
                                id="形状"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
  name: 'YbSvgPay',
  props: {
    colors: Object,
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
