<template>
  <section
    class="yb-layout"
    :class="{'yb-layout--horizontal': horizontal}">
    <slot></slot>
  </section>
</template>
<script>
export default {
  name: 'YbLayout',
  props: {
    horizontal: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
@include b(yb-layout) {
  display: flex;
  flex-direction: column;

  @include m(horizontal) {
    flex-direction: row;
  }
}
</style>
