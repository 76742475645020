<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 50 49"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>xxhdpi/ic_home_icon_gift</title>
        <defs>
            <linearGradient
                x1="50%"
                y1="80.8673469%"
                x2="70.4828369%"
                y2="37.00516%"
                id="linearGradient-1"
            >
                <stop stop-color="#FFA66F" offset="0%" />
                <stop stop-color="#FFC76F" stop-opacity="0.721563593" offset="100%" />
            </linearGradient>
            <linearGradient
                x1="47.6466448%"
                y1="34.9027312%"
                x2="50%"
                y2="60.1804293%"
                id="linearGradient-2"
            >
                <stop stop-color="#FFEB6B" offset="0%" />
                <stop stop-color="#FFC400" offset="100%" />
            </linearGradient>
            <linearGradient
                x1="47.6466448%"
                y1="48.7156837%"
                x2="50%"
                y2="50.8660435%"
                id="linearGradient-3"
            >
                <stop stop-color="#FFEB6B" offset="0%" />
                <stop stop-color="#FFC400" offset="100%" />
            </linearGradient>
        </defs>
        <g id="落地页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-30.000000, -1279.000000)" id="编组-8备份">
                <g transform="translate(0.000000, 1253.000000)">
                    <g id="ic_home_icon_gift" transform="translate(30.000000, 27.000000)">
                        <g id="编组-10" transform="translate(0.014719, -0.863961)">
                            <ellipse
                                id="椭圆形"
                                fill="#FF8400"
                                transform="translate(29.000000, 10.682257) rotate(30.000000) translate(-29.000000, -10.682257) "
                                cx="29"
                                cy="10.6822567"
                                rx="5"
                                ry="7"
                            />
                            <ellipse
                                id="椭圆形备份-13"
                                fill="#FF8400"
                                transform="translate(19.000000, 10.682257) scale(-1, 1) rotate(30.000000) translate(-19.000000, -10.682257) "
                                cx="19"
                                cy="10.6822567"
                                rx="5"
                                ry="7"
                            />
                            <path
                                d="M43.9852814,14.863961 C45.6421356,14.863961 46.9852814,16.2071068 46.9852814,17.863961 L46.9852814,22.863961 C46.9852814,24.5208153 45.6421356,25.863961 43.9852814,25.863961 L43.9852814,40.863961 C43.9852814,44.7299543 40.8512746,47.863961 36.9852814,47.863961 L16.9852814,47.863961 C13.1192881,47.863961 9.98528137,44.7299543 9.98528137,40.863961 L9.98528137,25.863961 L7.98528137,25.863961 C6.32842712,25.863961 4.98528137,24.5208153 4.98528137,22.863961 L4.98528137,17.863961 C4.98528137,16.2071068 6.32842712,14.863961 7.98528137,14.863961 L43.9852814,14.863961 Z"
                                id="形状结合"
                                fill="url(#linearGradient-1)"
                            />
                            <path
                                d="M39.9852814,21.863961 L39.9852814,37.863961 C39.9852814,39.5208153 39.3137085,41.0208153 38.2279221,42.1066017 C37.1421356,43.1923882 35.6421356,43.863961 33.9852814,43.863961 L33.9852814,43.863961 L13.9852814,43.863961 C12.3284271,43.863961 10.8284271,43.1923882 9.74264069,42.1066017 C8.65685425,41.0208153 7.98528137,39.5208153 7.98528137,37.863961 L7.98528137,37.863961 L7.98528137,21.863961 L39.9852814,21.863961 Z"
                                id="矩形备份-7"
                                stroke="#272727"
                                stroke-width="2"
                                fill="url(#linearGradient-2)"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M39.9852814,12.863961 C40.8137085,12.863961 41.5637085,13.1997475 42.1066017,13.7426407 C42.6494949,14.2855339 42.9852814,15.0355339 42.9852814,15.863961 L42.9852814,15.863961 L42.9852814,21.863961 L4.98528137,21.863961 L4.98528137,15.863961 C4.98528137,15.0355339 5.32106781,14.2855339 5.86396103,13.7426407 C6.40685425,13.1997475 7.15685425,12.863961 7.98528137,12.863961 L7.98528137,12.863961 Z"
                                id="矩形备份-13"
                                stroke="#272727"
                                stroke-width="2"
                                fill="url(#linearGradient-3)"
                                stroke-linejoin="round"
                            />
                            <rect
                                id="矩形备份-7"
                                fill="#272727"
                                x="21.9852814"
                                y="24.863961"
                                width="4"
                                height="16"
                                rx="2"
                            />
                            <rect
                                id="矩形备份-8"
                                fill="#272727"
                                x="21.9852814"
                                y="11.863961"
                                width="4"
                                height="11"
                                rx="2"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
  name: 'YbSvgGift',
  props: {
    colors: Object,
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
