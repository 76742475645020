const env = process.env.VUE_APP_ENV || 'development'
const versionName = process.env.VUE_APP_NAME || 'yb'

console.log(`------- 当前环境: ${JSON.stringify(process.env)} -------`)
console.log(`------- 当前版本: ${versionName === 'yb' ? '歪比歪比' : '玩呗'} -------`)
interface IConfig {
  baseURL: string;
  webLogUrl: string;
}

const defaultConfig: IConfig = {
  baseURL: versionName === 'yb' ? 'https://test-app.evkland.cn/box-api' : 'https://wb.evkland.cn',
  webLogUrl: 'https://s4.cnzz.com/z_stat.php?id=1279961443&web_id=1279961443'
}

const dev: IConfig = {
  ...defaultConfig
}

const test: IConfig = {
  ...defaultConfig
}

const prod: IConfig = {
  baseURL: versionName === 'yb' ? 'https://api.ybiybi.net' : 'https://api.iwanbei.com',
  webLogUrl: 'https://v1.cnzz.com/z_stat.php?id=1280075894&web_id=1280075894'
}

const beta: IConfig = {
  ...defaultConfig
}

let config: IConfig = dev

switch (env) {
  case 'development':
    config = dev
    break
  case 'test':
    config = test
    break
  case 'beta':
    config = beta
    break
  case 'production':
    config = prod
    break
}

export default config
