
export default {
  name: 'YbButton',
  props: {
    text: {
      type: String,
      require: true
    }
  }
}
