<template>
  <div class="yb-popup">
    <template v-if="qrcodeDateUrl">
      <div class="popup-qrcode">
        <p class="popup-qrcode__title">{{`手机扫码下载${popupTitle}App`}}</p>
        <img class="popup-qrcode__img" :src="qrcodeDateUrl" />
        <a :href="packageUrl" class="popup-qrcode__btn" @click="onClickDownloadBtn">
          <yb-svg-download class="popup-qrcode__btn--icon" :colors="['#FFA100', '#FFFFFF']" :width="'14px'" :height="'14px'" />
          <span>直接下载APK</span>
        </a>
      </div>
    </template>
    <template v-else>
      <div class="popup-box">
        <div class="popup-box__icon">
          <img class="popup-box__icon--img" :src="require(`../../assets/${popupBoxLogo}`)" />
        </div>
        <img class="popup-box__name" :src="require(`../../assets/${popupBoxNameLogo}`)" />

        <p  class="popup-box__desc">安装<span>{{popupTitle}}</span>{{popupText}}</p>
        <a class="popup-box__btn" @click="onClickDownloadBtn" :href="packageUrl">{{popupBoxBtnText}}</a>
      </div>
    </template>
  </div>
</template>

<script lang="ts">

import webLog from '@/utils/webLog'
import { onMounted, PropType } from 'vue'
import { IGameInfo } from '@/api/types/landingPage'

interface IPopupState {
  onClickDownloadBtn: () => void;
}

export default {
  props: {
    popupTitle: {
      type: String as PropType<string>,
      default: '歪比歪比'
    },
    popupBoxBtnText: {
      type: String as PropType<string>,
      default: '安装歪比歪比盒子'
    },
    popupBoxLogo: {
      type: String as PropType<string>,
      default: 'logo.png'
    },
    popupBoxNameLogo: {
      type: String as PropType<string>,
      default: 'name.png'
    },
    qrcodeDateUrl: {
      type: String,
      required: true
    },
    popupText: {
      type: String,
      required: true
    },
    packageUrl: {
      type: String,
      required: true
    },
    gameinfo: {
      type: Object as PropType<IGameInfo>,
      required: true
    },
    isWeblog: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  setup (
    props: Readonly<{
      gameinfo: IGameInfo;
      qrcodeDateUrl: string;
      isWeblog: boolean;
    }>
  ): IPopupState {
    onMounted(() => {
      if (props.isWeblog && !props.qrcodeDateUrl) {
        webLog.report('landpage', 'landpage_appshow', props.gameinfo.gameId + '')
      }
    })

    const onClickDownloadBtn = () => {
      // 是否需要打点
      if (props.isWeblog) {
        webLog.report('landpage_appwindow', 'landpage_appinstall', props.gameinfo.gameId + '')
      }
    }

    return {
      onClickDownloadBtn
    }
  }
}
</script>

<style lang="scss" scoped>
@include b(yb-popup) {
  padding-top: 75px;
  padding-bottom: 40px;
  width: 580px;
  text-align: center;
  background-color: #fff;
}

@include b(popup-qrcode) {
  @include e(title) {
    color: $color-text-secondary;
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 46px;
  }

  @include e(img) {
    width: 300px;
    height: 300px;
    border: 1px solid #ABABAB;
  }

  @include e(btn) {
    margin-top: 40px;
    font-size: 28px;
    color: #FFA100;
    cursor: pointer;
    display: block;

    span {
      vertical-align: top;
      margin-left: 4px;
    }

    @include m(icon) {
      vertical-align: bottom;
    }
  }
}

@include b(popup-box) {
  @include e(icon) {
    display: inline-block;

    @include m(img) {
      width: 180px;
      height: 180px;
      border-radius:  40px;
    }
  }

  @include e(name) {
    width: auto;
    height: 36px;
    display: block;
    margin: 0 auto;
    margin-top: 15px;
  }

  @include e(desc) {
    color: #ABABAB;
    font-size: 28px;
    margin-top: 20px;

    span {
      color: #FFA100;
    }
  }

  @include e(btn) {
    color: #272727;
    font-weight: bold;
    font-size: 30px;
    width: 400px;
    display: inline-block;
    border-radius: 40px;
    @include gradient(left, #FFE046, #FFCC23, #FFC200);
    padding: 16px;
    box-sizing: border-box;
    margin-top: 5px;
  }
}
</style>
