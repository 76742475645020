<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="落地页" transform="translate(-641.000000, -2291.000000)" :fill="colors[0]">
            <g id="游戏评价" transform="translate(-1.000000, 1826.000000)">
                <g id="评价1" transform="translate(29.000000, 115.000000)">
                    <g id="评论加点赞" transform="translate(518.000000, 350.000000)">
                        <g id="ic_common_icon_comment" transform="translate(95.000000, 0.000000)">
                            <path d="M24,12.5714286 L24,21.3109244 C24,22.7960598 22.7960598,24 21.3109244,24 L12.5714286,24 C6.25960286,24 1.14285714,18.8832543 1.14285714,12.5714286 C1.14285714,6.25960286 6.25960286,1.14285714 12.5714286,1.14285714 C18.8832543,1.14285714 24,6.25960286 24,12.5714286 Z M7.3,10 C6.58202983,10 6,10.4477153 6,11 C6,11.5522847 6.58202983,12 7.3,12 L17.7,12 C18.4179702,12 19,11.5522847 19,11 C19,10.4477153 18.4179702,10 17.7,10 L7.3,10 Z M7.33333333,15 C6.59695367,15 6,15.4477153 6,16 C6,16.5522847 6.59695367,17 7.33333333,17 L12.6666667,17 C13.4030463,17 14,16.5522847 14,16 C14,15.4477153 13.4030463,15 12.6666667,15 L7.33333333,15 Z" id="Combined-Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'YbSvgMessage',
  props: {
    colors: Object,
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
