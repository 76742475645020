<template>
  <svg :width="width" :height="height" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <linearGradient x1="50%" y1="100%" x2="66.4966964%" y2="28.9505072%" id="linearGradient-1">
            <stop stop-color="#FFA66F" offset="0%"></stop>
            <stop stop-color="#FFC76F" stop-opacity="0.721563593" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="48.2997009%" y1="16.0311453%" x2="50%" y2="72.9059659%" id="linearGradient-2">
            <stop stop-color="#FFEB6B" offset="0%"></stop>
            <stop stop-color="#FFC400" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-29.000000, -1863.000000)">
            <g transform="translate(-1.000000, 1826.000000)">
                <g transform="translate(30.000000, 37.000000)">
                    <rect x="0" y="0" :width="width" :height="height"></rect>
                    <path d="M13,7 L40,7 C42.209139,7 44,8.790861 44,11 L44,38 C44,42.418278 40.418278,46 36,46 L13,46 C10.790861,46 9,44.209139 9,42 L9,11 C9,8.790861 10.790861,7 13,7 Z" id="Rectangle-14备份" fill="url(#linearGradient-1)"></path>
                    <path d="M10,3 L36,3 C38.209139,3 40,4.790861 40,7 L40,37.3464103 C40,39.5555493 38.209139,41.3464103 36,41.3464103 C35.5465217,41.3464103 35.0963451,41.2692983 34.6687195,41.1183716 L23,37 L23,37 L11.3312805,41.1183716 C9.24808375,41.8536175 6.96328457,40.7608875 6.22803867,38.6776907 C6.07711198,38.2500651 6,37.7998885 6,37.3464103 L6,7 C6,4.790861 7.790861,3 10,3 Z" id="Rectangle-14" fill="url(#linearGradient-2)"></path>
                    <path d="M6,28 L40,35 L40,39 C40,41.209139 38.209139,43 36,43 L10,43 C7.790861,43 6,41.209139 6,39 L6,28 L6,28 Z" id="Rectangle-14" fill="#FFB400"></path>
                    <path d="M26,4 L36,4 L36,13.2132617 C36,14.3178312 35.1045695,15.2132617 34,15.2132617 C33.5976847,15.2132617 33.2047213,15.0919287 32.8724392,14.8651112 L31,13.5869747 L31,13.5869747 L29.1275608,14.8651112 C28.2152695,15.4878458 26.9708852,15.2531138 26.3481505,14.3408225 C26.1213331,14.0085404 26,13.615577 26,13.2132617 L26,4 L26,4 Z" id="矩形备份-11" fill="#F1B900"></path>
                    <path d="M22.5,29 C23.3284271,29 24,29.6715729 24,30.5 C24,31.3284271 23.3284271,32 22.5,32 L13.5,32 C12.6715729,32 12,31.3284271 12,30.5 C12,29.6715729 12.6715729,29 13.5,29 L22.5,29 Z M32.5,21 C33.3284271,21 34,21.6715729 34,22.5 C34,23.3284271 33.3284271,24 32.5,24 L13.5,24 C12.6715729,24 12,23.3284271 12,22.5 C12,21.6715729 12.6715729,21 13.5,21 L32.5,21 Z" id="Combined-Shape" fill="#272727"></path>
                    <path d="M21.9971426,4 L10,4 C7.790861,4 6,5.790861 6,8 L6,38 C6,40.209139 7.790861,42 10,42 L36,42 C38.209139,42 40,40.209139 40,38 L40,8 C40,5.790861 38.209139,4 36,4 L34,4 L34,4 L21.9971426,4 Z" id="路径-3" stroke="#272727" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M27,1 L33,1 C34.1045695,1 35,1.8954305 35,3 L35,13.2122031 C35,13.7644879 34.5522847,14.2122031 34,14.2122031 C33.8152262,14.2122031 33.6340653,14.1610093 33.4766197,14.0643023 L30.5233803,12.25035 C30.2023234,12.0531489 29.7976766,12.0531489 29.4766197,12.25035 L26.5233803,14.0643023 C26.0527789,14.3533573 25.4369558,14.2061848 25.1479008,13.7355834 C25.0511938,13.5781378 25,13.3969769 25,13.2122031 L25,3 C25,1.8954305 25.8954305,1 27,1 Z" id="矩形" fill="#FF8A00"></path>
                </g>
            </g>
        </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'YbSvgBook',
  props: {
    colors: Object,
    width: {
      type: String,
      default: '48'
    },
    height: {
      type: String,
      default: '48'
    }
  }
}
</script>
