/**
 * ts 接口
 * Author: Huangzhh
 */

/** 请求成功返回结构 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IAxiosSuccess<T = any> {
  /** 请求状态 */
  status: number;
  /** 请求信息 */
  info: string;
  /** 返回数据 */
  data: T;
}

/**
 * 请求失败信息
 */
interface IAxiosErrorInfo {
  /** 错误码 */
  code: string; // 类型地址 http://yapi.xf.io/project/416/wiki
  /** 错误信息 */
  message: string;
}

/**
 * 请求成功返回结构
 */
export interface IAxiosError {
  /** 接口状态  */
  status: number;
  /** 接口返回信息 */
  info: string;
  /** 错误详情 */
  errInfo: IAxiosErrorInfo;
}

/**
 * 请求成功
 *
 * @param type 返回类型
 */
export const isAxiosSuccess = (type: IAxiosSuccess | IAxiosError): type is IAxiosSuccess => {
  return (type as IAxiosSuccess).data !== undefined
}

/**
 * 请求是否
 *
 * @param type 返回类型
 */
export const isAxiosError = (type: IAxiosSuccess | IAxiosError): type is IAxiosError => {
  return (type as IAxiosError).status !== 1
}

/**
 * 获取游戏配置接口
 */
export interface IGetConfig {
    /**
     *
     */
    url: string;
}

/**
 * 请求结果
 */
export type IAxiosResponse = IAxiosSuccess | IAxiosError;
