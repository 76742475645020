<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="落地页" transform="translate(-350.000000, -734.000000)">
            <g id="游戏模块" transform="translate(0.000000, 551.000000)">
                <g id="star+score" transform="translate(260.000000, 175.000000)">
                    <g id="ic_icon_star_half" transform="translate(90.000000, 8.040787)">
                        <path d="M11.9998339,20.4141224 L6.78904794,22.6115569 C5.77127663,23.04076 4.59827206,22.5636313 4.16906891,21.54586 C4.03768532,21.2343095 3.98743272,20.8945353 4.02300758,20.5582917 L4.58379885,15.2578489 L4.58379885,15.2578489 L0.944813226,11.3604155 C0.1909924,10.5530569 0.234393359,9.28747092 1.04175198,8.53365009 C1.30986115,8.28331986 1.6416503,8.11154366 2.00082655,8.0371113 L7.41647216,6.91482311 L7.41647216,6.91482311 L10.291387,2.19124023 C10.8656595,1.2476911 12.0960969,0.948333189 13.0396461,1.52260562 C13.3127657,1.68883443 13.5420519,1.91812064 13.7082807,2.19124023 L16.5831955,6.91482311 L16.5831955,6.91482311 L21.9988412,8.0371113 C23.0804306,8.26124987 23.7755318,9.31975154 23.5513933,10.401341 C23.4769609,10.7605172 23.3051847,11.0923064 23.0548545,11.3604155 L19.4158689,15.2578489 L19.4158689,15.2578489 L19.9766601,20.5582917 C20.0928759,21.6567304 19.2966268,22.6414025 18.198188,22.7576182 C17.8619444,22.793193 17.5221702,22.7429404 17.2106198,22.6115569 L11.9998339,20.4141224 L11.9998339,20.4141224 Z" id="ic_icon_star_hard" fill="#ABABAB"></path>
                        <path d="M11.9999588,1.23076298 L11.9998339,20.4141224 L6.78904794,22.6115569 C5.77127663,23.04076 4.59827206,22.5636313 4.16906891,21.54586 C4.03768532,21.2343095 3.98743272,20.8945353 4.02300758,20.5582917 L4.58379885,15.2578489 L0.944813226,11.3604155 C0.1909924,10.5530569 0.234393359,9.28747092 1.04175198,8.53365009 C1.30986115,8.28331986 1.6416503,8.11154366 2.00082655,8.0371113 L7.41647216,6.91482311 L10.291387,2.19124023 C10.6676473,1.57303174 11.3255863,1.23136181 11.9999588,1.23076298 Z" id="形状结合" fill="#FFCF03"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>
<script>
export default {
  name: 'YbSvgStarHalf',
  props: {
    colors: Object,
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
