<template>
  <yb-footer class="footer">
    <yb-row type="flex" justify="space-between" ph30 pv14>
      <yb-col span="20" class="footer__left">
        <yb-button class="footer__left--button" @click="onClick">{{gameinfo.reserve ? '立即预约' : '开始游戏'}}</yb-button>
      </yb-col>
      <yb-col span="4" class="footer__right" @click="goTop" pl30>
        <yb-svg-rocket width="24px" height="24px" />
        <div class="footer__right--text">返回顶部</div>
      </yb-col>
    </yb-row>
  </yb-footer>
</template>

<script lang="ts">
import { PropType } from '@vue/runtime-core'
import { OpenAppEnum } from './types'
import { IGameInfo } from '@/api/types/landingPage'

interface IFooterState {
  goTop: () => void;
  onClick: () => void;
}

export default {
  props: {
    gameAlias: {
      type: String,
      required: true
    },
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: true
    },
    gameinfo: {
      type: Object as PropType<IGameInfo>,
      required: true
    }
  },
  setup (
    props: Readonly<{
      gameAlias: string;
      handleOpenApp: (position: OpenAppEnum) => void;
    }>
  ): IFooterState {
    const goTop = () => {
      const content = document.querySelector('.app-content')
      if (content) {
        content.scrollTop = 0
      }
    }

    const onClick = () => {
      props.handleOpenApp(OpenAppEnum.START)
    }

    return {
      goTop,
      onClick
    }
  }
}
</script>

<style lang="scss" scoped>
@include b(footer) {
  max-width: $max-window-width;
  background-color: #FFFFFF;
  border-top: 1px solid #EBEBEB;
  @include e(left) {
    @include m(button) {
      height: 80px;
      font-weight: bold;
      font-size: 30px;
      color: #272727;
      @include gradient(left, #FFE046, #FFCC23, #FFC200);
      box-sizing: border-box;
      border-radius: 40px;
    }
  }

  @include e(right) {
    text-align: center;
    @include m(logo) {
      width: 48px;
      height: 48px;
    }
    @include m(text) {
      margin-top: 8px;
      font-size: 20px;
      color: #727272;
    }
  }
}
</style>
