/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Col from 'vant/lib/col'
import 'vant/lib/col/index.css'
import Row from 'vant/lib/row'
import 'vant/lib/row/index.css'
import { Layout, Header, Content, Footer } from './layout'
import YbButton from './button'
import YbCard from './card'
import SvgStar from './svg-icon/svg-star.vue'
import SvgStarHalf from './svg-icon/svg-star-half.vue'
import SvgTag from './svg-icon/svg-tag.vue'
import SvgChat from './svg-icon/svg-chat.vue'
import SvgGame from './svg-icon/svg-game.vue'
import SvgMessage from './svg-icon/svg-message.vue'
import SvgLike from './svg-icon/svg-like.vue'
import SvgArrow from './svg-icon/svg-arrow.vue'
import SvgRocket from './svg-icon/svg-rocket.vue'
import SvgDownload from './svg-icon/svg-download.vue'
import SvgPlay from './svg-icon/svg-play.vue'
import SvgVip from './svg-icon/svg-vip.vue'
import SvgPay from './svg-icon/svg-pay.vue'
import SvgRebate from './svg-icon/svg-rebate.vue'
import SvgOther from './svg-icon/svg-other.vue'
import SvgWelfareParty from './svg-icon/svg-welfare-party.vue'
import SvgGift from './svg-icon/svg-gift.vue'
import SvgCoupon from './svg-icon/svg-coupon.vue'

export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  install: (app: any) => {
    app.component('YbCol', Col)
    app.component('YbRow', Row)
    app.component('YbLayout', Layout)
    app.component('YbHeader', Header)
    app.component('YbContent', Content)
    app.component('YbFooter', Footer)
    app.component('YbButton', YbButton)
    app.component('YbCard', YbCard)
    app.component('YbSvgStar', SvgStar)
    app.component('YbSvgStarHalf', SvgStarHalf)
    app.component('YbSvgChat', SvgChat)
    app.component('YbSvgTag', SvgTag)
    app.component('YbSvgMessage', SvgMessage)
    app.component('YbSvgLike', SvgLike)
    app.component('YbSvgArrow', SvgArrow)
    app.component('YbSvgGame', SvgGame)
    app.component('YbSvgRocket', SvgRocket)
    app.component('YbSvgDownload', SvgDownload)
    app.component('YbSvgPlay', SvgPlay)
    app.component('YbSvgVip', SvgVip)
    app.component('YbSvgPay', SvgPay)
    app.component('YbSvgRebate', SvgRebate)
    app.component('YbSvgOther', SvgOther)
    app.component('YbSvgWelfareParty', SvgWelfareParty)
    app.component('YbSvgGift', SvgGift)
    app.component('YbSvgCoupon', SvgCoupon)
  }
}
