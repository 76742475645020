<template>
    <yb-header fixed class="header" :style="[background ? `background-color: ${background}` : '#FFC400']">
        <yb-row type="flex" justify="space-between" ph30 pv14>
            <yb-col span="12" class="header__left">
                <img class="header__left--logo" radius8 :src="require(`../../assets/${headerLogo}`)" alt />
                <img class="header__left--name" ml9 :src="require(`../../assets/${headerNameLogo}`)" alt />
            </yb-col>
            <yb-col span="8" class="header__right">
                <a @click="handleDownload">
                    <yb-button class="header__right--button" fr>{{buttonText}}</yb-button>
                </a>
            </yb-col>
        </yb-row>
    </yb-header>
</template>
<script lang="ts">
import { OpenAppEnum } from './types'
import { PropType } from 'vue'

interface IHeaderState {
  handleDownload: () => void;
}

export default {
  props: {
    background: {
      type: String as PropType<string>
    },
    headerLogo: {
      type: String as PropType<string>,
      default: 'logo.png'
    },
    headerNameLogo: {
      type: String as PropType<string>,
      default: 'name.png'
    },
    buttonText: {
      type: String as PropType<string>,
      default: '下载App'
    },
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: true
    }
  },
  setup (props: Readonly<{
    handleOpenApp: (position: OpenAppEnum) => void;
  }>): IHeaderState {
    const handleDownload = () => {
      props.handleOpenApp(OpenAppEnum.DOWNLOAD)
    }
    return {
      handleDownload
    }
  }
}
</script>

<style lang="scss" scoped>
@include b(header) {
  max-width: $max-window-width;
  @include e(left) {
    @include m(logo) {
      box-sizing: border-box;
      width: 60px;
      height: 60px;
      border: 1px solid $border-color-base;
    }
    @include m(name) {
      display: inline-block;
      height: 36px;
      color: #ffffff;
      vertical-align: middle;
      font-weight: bold;
      width: auto;
    }
  }
  @include e(right) {
    @include m(button) {
      width: 160px;
      height: 64px;
      font-weight: bold;
      font-size: 28px;
      color: $color-text-secondary;
      background-color: #fff;
      border-radius: 32px;
      -webkit-user-select: none;
    }
  }
}
</style>
