const name = process.env.VUE_APP_NAME || 'yb'

interface DetailsConfig {
  logo: string;
  nameLogo: string;
  title: string;
  btnText: string;
  qrCodeLogo: string;
}

const defaultDetails: DetailsConfig = {
  logo: 'logo.png',
  nameLogo: 'name.png',
  title: '歪比歪比',
  btnText: '安装歪比歪比盒子',
  qrCodeLogo: 'group_code_logo.png'
}

const ybDetails: DetailsConfig = {
  ...defaultDetails
}

const wbDetails: DetailsConfig = {
  logo: 'logo_wb.png',
  nameLogo: 'name_wb.png',
  title: '玩呗',
  btnText: '安装玩呗',
  qrCodeLogo: 'group_code_logo-wb.png'
}

let detailsConfig: DetailsConfig = ybDetails

switch (name) {
  case 'yb':
    detailsConfig = ybDetails
    break
  case 'wb':
    detailsConfig = wbDetails
    break
}
export default detailsConfig
