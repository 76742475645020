import config from '@/config'

class WebLog {
  private isAdded = false;

  append () {
    if (this.isAdded) {
      return
    }
    const el: HTMLScriptElement = document.createElement('script')
    el.type = 'text/javascript'
    el.charset = 'utf-8'
    el.async = true
    const ref: HTMLScriptElement = document.getElementsByTagName('script')[0]
    // eslint-disable-next-line no-unused-expressions
    ref?.parentNode?.insertBefore(el, ref)
    el.src = config.webLogUrl
    this.isAdded = true
  }

  /**
   * 打点上报
   * @param category 表示事件发生在谁身上
   * @param action 表示访客跟元素交互的行为动作
   * @param label 用于更详细的描述事件
   * @param value 用于填写打分型事件的分值
   * @param nodeid 填写事件原色的div元素id
   */
  report (category: string, action: string, label: string, value?: number, nodeid?: string) {
    if (window._czc) {
      window._czc.push(['_trackEvent', category, action, label, value, nodeid])
    }
  }
}

export default new WebLog()
