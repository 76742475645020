
import { PropType } from '@vue/runtime-core'
import { OpenAppEnum } from './types'

interface IHeaderState {
  handleDownload: () => void;
}

export default {
  props: {
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: true
    }
  },
  setup (props: Readonly<{
    handleOpenApp: (position: OpenAppEnum) => void;
  }>): IHeaderState {
    const handleDownload = () => {
      props.handleOpenApp(OpenAppEnum.DOWNLOAD)
    }

    return {
      handleDownload
    }
  }
}
