<template>
  <div class="error-box">
    <div class="error-box__content">
      <img src="../../assets/empty.png" />
      <p>暂无数据，下载App发现更多游戏吧</p>
      <yb-button @click="handleDownload" class="error-box__content--button">下载App</yb-button>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from '@vue/runtime-core'
import { OpenAppEnum } from './types'

interface IHeaderState {
  handleDownload: () => void;
}

export default {
  props: {
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: true
    }
  },
  setup (props: Readonly<{
    handleOpenApp: (position: OpenAppEnum) => void;
  }>): IHeaderState {
    const handleDownload = () => {
      props.handleOpenApp(OpenAppEnum.DOWNLOAD)
    }

    return {
      handleDownload
    }
  }
}
</script>

<style lang="scss" scoped>
@include b(error-box) {
  background-color: #fff;
  height: 100vh;
  position: relative;

  @include e(content) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    img {
      width: 400px;
    }

    p {
      font-size: 28px;
      color: $color-text-secondary;
      font-weight: normal;
      margin-bottom: 20px;
    }

    @include m(button) {
      width: 244px;
      height: 80px;
      font-weight: bold;
      font-size: 30px;
      @include gradient(left, #FFE046, #FFCC23, #FFC200);
      border-radius: 40px;
      color: #272727;
      margin: 0 auto;
      box-sizing: border-box;
    }
  }
}
</style>
