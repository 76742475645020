<template>
  <svg :width="width" :height="height" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ic_icon_top</title>
    <g id="落地页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-651.000000, -5154.000000)" id="bar-预约备份">
            <g transform="translate(0.000000, 5138.000000)">
                <g id="编组-3" transform="translate(651.000000, 16.000000)">
                    <rect id="Rectangle-10" x="0" y="0" width="48" height="48"></rect>
                    <path d="M24,2 C31.7319865,2 38,14.8611593 38,24.25 C38,24.3390963 37.9995064,24.4279251 37.9982269,24.5143625 C40.4111932,26.1287244 42,28.8788193 42,32 C42,34.3492151 41.0999247,36.4882064 39.6260325,38.0902752 C38.7919308,36.0855645 37.6058152,34.2640991 36.1455343,32.7043284 C35.6343595,33.7310167 35.0175074,34.7004355 34.2949782,35.6125851 L33.9788528,36 L14.0211472,36 C13.1608927,34.9762436 12.4386655,33.8776864 11.8544657,32.7043284 C10.3941848,34.2640991 9.20806921,36.0855645 8.3739675,38.0902752 C6.90007533,36.4882064 6,34.3492151 6,32 C6,28.8788193 7.5888068,26.1287244 10.0017731,24.5143625 C10.0004936,24.4279251 10,24.3390963 10,24.25 C10,14.8611593 16.2680135,2 24,2 Z M24,12 C21.2385763,12 19,14.2385763 19,17 C19,19.7614237 21.2385763,22 24,22 C26.7614237,22 29,19.7614237 29,17 C29,14.2385763 26.7614237,12 24,12 Z" id="形状结合" fill="#272727"></path>
                    <path d="M24,22 C26.7614237,22 29,20.3137085 29,17 C29,13.6862915 26.7614237,10 24,10 C21.2385763,10 19,13.6862915 19,17 C19,20.3137085 21.2385763,22 24,22 Z" id="椭圆形" fill="#FFC400"></path>
                    <path d="M24,46 C25.1045695,46 26,43.209139 26,41 C26,38.790861 25.1045695,38 24,38 C22.8954305,38 22,38.790861 22,41 C22,43.209139 22.8954305,46 24,46 Z" id="椭圆形" fill="#FFC400"></path>
                    <path d="M18,44 C19.1045695,44 20,41.9068542 20,40.25 C20,38.5931458 19.1045695,38 18,38 C16.8954305,38 16,38.5931458 16,40.25 C16,41.9068542 16.8954305,44 18,44 Z" id="椭圆形备份-2" fill="#FFC400"></path>
                    <path d="M30,44 C31.1045695,44 32,41.9068542 32,40.25 C32,38.5931458 31.1045695,38 30,38 C28.8954305,38 28,38.5931458 28,40.25 C28,41.9068542 28.8954305,44 30,44 Z" id="椭圆形备份-3" fill="#FFC400"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: 'YbSvgRocket',
  props: {
    colors: Object,
    width: {
      type: String,
      default: '48'
    },
    height: {
      type: String,
      default: '48'
    }
  }
}
</script>
