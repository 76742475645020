

import webLog from '@/utils/webLog'
import { onMounted, PropType } from 'vue'
import { IGameInfo } from '@/api/types/landingPage'

interface IPopupState {
  onClickDownloadBtn: () => void;
}

export default {
  props: {
    popupTitle: {
      type: String as PropType<string>,
      default: '歪比歪比'
    },
    popupBoxBtnText: {
      type: String as PropType<string>,
      default: '安装歪比歪比盒子'
    },
    popupBoxLogo: {
      type: String as PropType<string>,
      default: 'logo.png'
    },
    popupBoxNameLogo: {
      type: String as PropType<string>,
      default: 'name.png'
    },
    qrcodeDateUrl: {
      type: String,
      required: true
    },
    popupText: {
      type: String,
      required: true
    },
    packageUrl: {
      type: String,
      required: true
    },
    gameinfo: {
      type: Object as PropType<IGameInfo>,
      required: true
    },
    isWeblog: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  setup (
    props: Readonly<{
      gameinfo: IGameInfo;
      qrcodeDateUrl: string;
      isWeblog: boolean;
    }>
  ): IPopupState {
    onMounted(() => {
      if (props.isWeblog && !props.qrcodeDateUrl) {
        webLog.report('landpage', 'landpage_appshow', props.gameinfo.gameId + '')
      }
    })

    const onClickDownloadBtn = () => {
      // 是否需要打点
      if (props.isWeblog) {
        webLog.report('landpage_appwindow', 'landpage_appinstall', props.gameinfo.gameId + '')
      }
    }

    return {
      onClickDownloadBtn
    }
  }
}
