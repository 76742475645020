
import { PropType } from '@vue/runtime-core'
import { OpenAppEnum } from './types'
import { IGameInfo } from '@/api/types/landingPage'

interface IFooterState {
  goTop: () => void;
  onClick: () => void;
}

export default {
  props: {
    gameAlias: {
      type: String,
      required: true
    },
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: true
    },
    gameinfo: {
      type: Object as PropType<IGameInfo>,
      required: true
    }
  },
  setup (
    props: Readonly<{
      gameAlias: string;
      handleOpenApp: (position: OpenAppEnum) => void;
    }>
  ): IFooterState {
    const goTop = () => {
      const content = document.querySelector('.app-content')
      if (content) {
        content.scrollTop = 0
      }
    }

    const onClick = () => {
      props.handleOpenApp(OpenAppEnum.START)
    }

    return {
      goTop,
      onClick
    }
  }
}
