import config from '../../config'
import { IAxiosSuccess } from '../interface'
import { IGameInfo, ICommentList, IRecommendGameList, IChannelUrl, GroupInfo, GroupPackUrl, ITrumpetMessage, IGiftList, ICouponInfo, IActivityDetail } from '../types/landingPage'
import server from '../axiosInstance'

const { baseURL } = config

const debug = false
let url = baseURL
if (debug) {
  url = 'http://yapi.xf.io/mock/496'
}

/**
 * 获取游戏数据
 */
export const getGameInfo = (gameAlias: string): Promise<IAxiosSuccess<IGameInfo>> => {
  return server.request<null, IAxiosSuccess<IGameInfo>>({
    url: `${url}/v1/landing-page`,
    method: 'GET',
    params: {
      gameAlias
    }
  })
}

/**
 * 评论
 */
export const getCommentList = (gameId: number): Promise<IAxiosSuccess<ICommentList>> => {
  return server.request<null, IAxiosSuccess<ICommentList>>({
    url: `${url}/v1/landing-page/getCommentInfo`,
    method: 'GET',
    params: {
      gameId
    }
  })
}

/**
 * 评论
 */
export const getRecommendGameList = (gameId: number, page: number): Promise<IAxiosSuccess<IRecommendGameList>> => {
  return server.request<null, IAxiosSuccess<IRecommendGameList>>({
    url: `${url}/v1/landing-page/getRelatedRecommendation`,
    method: 'GET',
    params: {
      size: 24,
      current: page,
      gameId
    }
  })
}

/**
 * 根据渠道包id查询抛包地址
 */
export const getChannelURL = (packageId: string): Promise<IAxiosSuccess<IChannelUrl>> => {
  return server.request<null, IAxiosSuccess<IChannelUrl>>({
    url: `${url}/v1/landing-page/getChanelURL`,
    method: 'GET',
    params: {
      chanelPackageId: packageId
    }
  })
}

/**
 * 获取群分享群数据
 */
export const getGroupMessageUrl = (groupId: string): Promise<IAxiosSuccess<GroupInfo>> => {
  return server.request<null, IAxiosSuccess<GroupInfo>>({
    url: `${url}/v1/landing-page/groupDetail`,
    method: 'GET',
    params: {
      groupId: groupId
    }
  })
}

/**
 * 根据渠道包id查询抛包地址
 */
export const getGroupBoxURL = (groupId: string, packageId: string): Promise<IAxiosSuccess<GroupPackUrl>> => {
  return server.request<null, IAxiosSuccess<GroupPackUrl>>({
    url: `${url}/v1/landing-page/groupDetail`,
    method: 'GET',
    params: {
      groupId: groupId,
      chanelPackageId: packageId
    }
  })
}

/**
 * 获取小喇叭消息列表
 */
export const getTrumpetMessage = (gameId: number): Promise<IAxiosSuccess<ITrumpetMessage>> => {
  return server.request<null, IAxiosSuccess<ITrumpetMessage>>({
    url: `${url}/v1/game-dynamics/getGameDynamicsByGameId`,
    method: 'GET',
    params: {
      gameId: gameId
    }
  })
}

/**
 * 获取礼包列表
 */
export const getGiftList = (gameId: number): Promise<IAxiosSuccess<IGiftList>> => {
  return server.request<null, IAxiosSuccess<IGiftList>>({
    url: `${url}/v1/landing-page/getGiftList`,
    method: 'GET',
    params: {
      gameId: gameId
    }
  })
}

/**
 * 获取代金券列表
 */
export const getCouponList = (gameId: number): Promise<IAxiosSuccess<ICouponInfo>> => {
  return server.request<null, IAxiosSuccess<ICouponInfo>>({
    url: `${url}/v1/landing-page/getGameCouponList`,
    method: 'GET',
    params: {
      gameId: gameId
    }
  })
}

/**
 * 获取代金券列表
 */
export const getActivityDetailById = (activityId: string): Promise<IAxiosSuccess<IActivityDetail>> => {
  return server.request<null, IAxiosSuccess<IActivityDetail>>({
    url: `${url}/v1/game-activity/getActivityById`,
    method: 'GET',
    params: {
      activityId: activityId
    }
  })
}
